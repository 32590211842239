// 筛选
<template>
  <div id="app3">
    <!-- 顶部 -->
    <Top ref="top" :white="$store.state.model.black"></Top>
    <div v-if="$store.state.islogin">
      <div class="history_body-2">
        <div class="filbox">
          <!-- 大分类 -->
          <div class="filter_top">
            <a
              class="filter_list"
              :class="{ 'filter_list-act': !menu_act }"
              @click="changerFilter(null, null)"
            ></a>
            <template v-for="(item, index) in menutotal">
              <a
                v-if="item.show"
                class="filter_list"
                :key="index"
                :class="{ 'filter_list-act': menu_act - 1 == index }"
                @click="changerFilter(index + 1, item.id, item.symbol)"
              >
                {{ item.name }}
              </a>
            </template>
          </div>
          <!-- 联动二级分类筛选 -->
          <div class="filter_top_fu" v-if="menutotal[menu_act - 1]">
            <a
              class="fil_box category_id_fil fil_fenlei"
              :class="{ 'fil-act': !menu_fu_act || menu_fu_act == 0 }"
              @click="changecategory_id(0, null)"
            >
              全部分类
            </a>
            <a
              v-for="(item, index) in menutotal[menu_act - 1].list"
              :key="index"
              :class="{ 'fil-act': menu_fu_act - 1 == index }"
              class="fil_box category_id_fil fil_fenlei"
              @click="changecategory_id(index + 1, item.id)"
            >
              {{ item.name }}
            </a>
          </div>
          <transition name="showc">
            <div class="filter_body_box" v-show="ccc1">
              <!-- 标签筛选 -->
              <div class="filter_body">
                <div class="filb_left">
                  <div class="filter_body_list" v-for="(item, key) in ccc1" :key="key">
                    <div
                      :class="[key + '_fil', { 'fil-act': ccc2[key] == null || ccc2[key] < 0 }]"
                      class="fil_box"
                      @click="changerList(key, null)"
                    >
                      <a class="fil">全部{{ lang[key] }}</a>
                    </div>
                    <div
                      :class="[key + '_fil', { 'fil-act': ccc2[key] == index }]"
                      class="fil_box"
                      v-for="(value, index) in item"
                      :key="index"
                      @click="changerList(key, index)"
                    >
                      <a class="fil">
                        {{ value }}
                      </a>
                    </div>
                  </div>
                </div>
                <!-- 是否付费 -->
                <div>
                  <div class="filb_left">
                    <div class="filter_body_list">
                      <div
                        class="fil_box paid_fil"
                        :class="{
                          'fil-act': (filact.paid != 1 && filact.paid != 0) || filact.paid == '',
                        }"
                        @click="changepaid(0, null)"
                      >
                        <a class="fil">是否付费</a>
                      </div>
                      <div
                        class="fil_box paid_fil"
                        :class="{ 'fil-act': filact.paid == 1 }"
                        @click="changepaid(1, 1)"
                      >
                        <a class="fil">付费</a>
                      </div>
                      <div
                        class="fil_box paid_fil"
                        :class="{ 'fil-act': filact.paid == 0 && filact.paid != '' }"
                        @click="changepaid(2, '0')"
                      >
                        <a class="fil">免费</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 广告 -->
              <div
                class="filgg"
                v-if="guanggaolist['pc-filter-right']"
                @click="cliguanggao('pc-filter-right')"
              >
                <img :src="guanggaolist['pc-filter-right'][0].source" alt="搜索页广告" />
              </div>
            </div>
          </transition>
          <!-- 状态筛选 -->
          <div class="filter_sele">
            <div class="fil_sele_box">
              <div
                class="fil_sele-button"
                :class="{ 'fil_sele-button-act': filact.sort_field == item.type }"
                v-for="(item, index) in sortlist"
                :key="index"
                @click="clisort(item, index)"
              >
                <a class="fil_sele-title">{{ item.name }}</a>
                <div class="fil_sele-icon"></div>
              </div>
            </div>
            <div class="search_goal-total" v-show="searchkeyword">
              <div class="search_goal">
                <a>{{ searchkeyword }}</a>
                <div class="search_goal-icon" @click="nonekeywordSearch"></div>
              </div>
            </div>
            <a class="search_t">
              共有
              <a>{{ video_total }}</a>
              个搜索结果
            </a>
          </div>
          <div class="filter_m">
            <transition name="mcenter_11">
              <div class="filter_m_body" v-show="video_enter">
                <!-- <div v-if="video_list" class="fil_q_total">
                <div class="fil_q_box" v-for="(item, index) in video_list.slice(0, 6)" :key="index">
                  <div class="fil_q_left">
                    <img
                      :onerror="$store.state.defaultimg"
                      :src="item.pic ? item.pic : ''"
                      :alt="item.title"
                    />
                  </div>
                  <div class="fil_q_right"></div>
                </div>
              </div> -->
                <div class="fil_a_total">
                  <a
                    v-for="(item, index) in video_list"
                    :key="index"
                    class="filter_m_list filter_m_list2"
                  >
                    <!-- <img class="filter_m_img" :src="item.pic" />
              <div class="filter_m_nodes">
                <a>{{ item.title }}</a>
                <p>{{ item.description }}</p>
              </div> -->
                    <div class="content-img">
                      <a
                        :href="YS.returnURL({ path: '/play', query: { video_id: item.id } }, true)"
                        target="_blank"
                      >
                        <img
                          :onerror="
                            $refs.top.ub_index == 2
                              ? $store.state.defaultimg_bo
                              : $refs.top.ub_index == 3
                              ? $store.state.defaultimg_u
                              : $store.state.defaultimg
                          "
                          :src="item.pic"
                          class="bodyimg"
                          :alt="item.pic ? item.title : ''"
                          :class="item.picLoad ? 'anim' : ''"
                        />

                        <!-- :src="loadOver(item.pic) ? item.pic : ''" -->
                      </a>
                      <!-- @click="YS.routetogo({ path: '/play', query: { video_id: item.id } }, true)" -->
                      <div class="ci-nodes">
                        <p class="cin-title" :title="item.title">{{ item.title || '无名称' }}</p>
                        <div class="cin-nodes">
                          <a v-show="item.year">{{ item.year }}</a>
                          <a v-show="$store.state.menudist[item.category_id]">
                            {{ $store.state.menudist[item.category_id] }}
                          </a>
                          <a v-show="item.region">{{ item.region }}</a>
                          <a v-show="item.language">{{ item.language }}</a>
                        </div>
                        <div class="cin-introduction" :title="item.description">
                          <span>{{ item.description }}</span>
                        </div>
                        <div class="cin-watch">
                          <a
                            :href="
                              YS.returnURL({ path: '/play', query: { video_id: item.id } }, true)
                            "
                            target="_blank"
                            class="cw-button"
                          >
                            <span class="to_play">立即观看</span>
                            <img
                              class="cwb_img"
                              src="../assets/img/homeplay.png"
                              alt="跳转到播放资源的网站"
                            />
                          </a>
                          <!-- 收藏 -->
                          <div
                            class="film_list-1"
                            @click="clifavorite(item.in_favorite, item.id, index)"
                          >
                            <div :class="item.in_favorite ? 'f_i-4lat-act' : 'f_i-4lat'"></div>
                          </div>
                          <div class="cw-num">
                            <img
                              height="12"
                              width="11"
                              src="../assets/img/renqi.png"
                              alt="人气图标"
                            />
                            <span>{{ item.hits }}</span>
                          </div>
                          <!-- <div class="cw-num">
                      <img height="12" width="11" src="../assets/img/renqi.png" />
                      <span>{{ item.hits }}</span>
                    </div> -->
                        </div>
                      </div>
                    </div>
                    <!-- 方框下内容 -->
                    <div class="content-nodes">
                      <a
                        class="cl-t"
                        :title="item.title"
                        :href="YS.returnURL({ path: '/play', query: { video_id: item.id } }, true)"
                        target="_blank"
                      >
                        {{ item.title }}
                      </a>
                      <p
                        class="cl-n"
                        :title="
                          '更新至第' +
                          item.last_fragment_symbol +
                          (item.parent_category_id == 102 ? '期' : '集')
                        "
                        v-show="item.state == '更新中' && item.multiple_fragment"
                      >
                        更新至第{{ item.last_fragment_symbol
                        }}{{ item.parent_category_id == 102 ? '期' : '集' }}
                      </p>
                      <p
                        class="cl-n"
                        :title="
                          item.last_fragment_symbol +
                          (item.parent_category_id == 102 ? '期' : '集') +
                          '全'
                        "
                        v-show="item.state == '已完结' && item.multiple_fragment"
                      >
                        {{ item.last_fragment_symbol
                        }}{{ item.parent_category_id == 102 ? '期' : '集' }}全
                      </p>
                      <p class="cl-n" :title="item.description" v-show="!item.multiple_fragment">
                        {{ item.description }}
                      </p>
                    </div>
                  </a>
                </div>

                <!-- 没有内容时 -->
                <div class="fil_none" v-show="!video_list && !loading">
                  <img src="../assets/img/mnone@2x.png" alt="提示没有任何内容" />
                  <span>没有更多内容...</span>
                </div>
                <!-- 正在加载 -->
                <div class="fil_none_loading" v-show="loading">
                  <img
                    class="load0"
                    src="../assets/img/loading.png"
                    height="100"
                    width="100"
                    alt="加载图片"
                  />
                </div>
              </div>
            </transition>
          </div>
          <transition name="show_fivPush">
            <div v-show="fivPushNum > 0" class="fivPush"><div class="dots-6"></div></div>
          </transition>
          <transition name="show_fivPush">
            <div v-show="fivPushNum < 0" class="fivPush fivPushNone">
              <img src="../assets/img/dixian.png" />
              <span>已经到最底部了</span>
            </div>
          </transition>
          <!-- 分页 -->
          <!-- <div class="pagination2" v-if="fy.pageList && fy.pagenum > 1 ">
          <div class="pagebody">
            <div class="page_left" @click="YS.pagebutton('left', fy, getrou)">
              <img src="../assets/img/left.png" alt="上一页"  />
            </div>
            <div class="page_cent">
              <div
                class="page_num"
                :class="{ 'page_num-act': fy.pages.page == item }"
                v-for="(item, index) in fy.pageList"
                :key="index"
                @click="YS.clickPage(item, fy, getrou)"
              >
                <a>{{ item }}</a>
              </div>
            </div>

            <div class="page_right" @click="YS.pagebutton('right', fy, getrou)">
              <img src="../assets/img/right.png" alt="下一页" />
            </div>
                        <div class="page_total">
              <input
                class="fenye_input"
                v-model="fy.jump"
                @focus="YS.getInputFocus($event)"
                @keyup.enter="YS.clickPage(fy.jump, fy, getPlaylist)"
              />
            </div>
            <div class="page_total">
              <span>共{{ fy.pagenum }}页</span>
            </div>
          </div>
        </div> -->
        </div>
      </div>
      <Bottom v-show="!loading"></Bottom>
    </div>
  </div>
</template>
<script>
let that;
import Top from '../components/Top'; //导航top
import Bottom from '../components/Bottom'; //导航top
export default {
  components: {
    Top,
    Bottom,
  },
  name: 'Fil',
  computed: {
    head: (data) => {
      if (data) {
        return window.location.origin + data.path;
      }
    },
    // isnormal: () => {
    //   var code = true;
    //   if (
    //     that.menu_act > 0 &&
    //     that.menutotal &&
    //     that.menutotal.length &&
    //     that.menutotal[that.menu_act - 1] &&
    //     that.menutotal[that.menu_act - 1].name === '午夜版'
    //   ) {
    //     code = false;
    //   }
    //   return code;
    // },
  },
  data() {
    return {
      ub_index: 1,
      hiddenError: false,
      hiddenValue: '', //隐藏密码
      loadCli: false,
      fivPushNum: 0,
      imgDurl: require('../assets/img/fil_b.png'),
      searchBefore: '',
      loading: true, //打开加载状态
      video_enter: true,
      isHiddenEnter: false,
      menutotal: [],
      guanggaolist: [],
      //视频列表,视频总数
      video_list: [],
      video_total: null,
      menu_act: null, //顶级分类选择下标
      menu_fu_act: null, //二级分类选择下标
      search: {
        total: 9999,
      },

      lang: {
        languages: '语言',
        years: '年份',
        regions: '地区',
        states: '状态',
        mosaic: '打码',
        tags: '喜好',
      },
      ccc1: null, //带s的参数字段
      //记录顶部分类的选值，不带s的参数字段（自己转换）
      sortact: 0,
      filact: {
        parent_category_id: null,
        category_id: null,
        language: null,
        year: null,
        region: null,
        state: null,
        keyword: '',
        paid: null,
        page: null,
        pagesize: null,
        sort_field: 'create_time', //排序字段
        sort_type: 'desc', //默认降序
        mosaic: null,
        tag: null,
      },
      filactNone: {
        parent_category_id: '',
      },
      searchkeyword: '', //搜索词克隆
      //排序字段
      sortlist: [
        { name: '添加时间', type: 'create_time' },
        { name: '更新时间', type: 'update_time' },
        { name: '评分', type: 'score' },
        { name: '热度', type: 'hits' },
      ],
      //记录当前筛选的选值,下标值
      ccc2: {
        languages: null,
        years: null,
        regions: null,
        states: null,
        mosaic: null,
        tags: null,
      },
      fy: {
        pagenum: null,
        pagetotal: 20,
        pages: {
          page: 1,
          pagesize: 21,
        },
        mobanpage: [1, 2, 3, 4, 5, 6, 7, 8],
        pageList: [1, 2, 3, 4, 5, '...', 11, 12],
      },
      filwindowScroll: () => {},
    };
  },

  created() {
    that = this;
    //var ismob = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    if (that.YS.ismob()) {
      //移动端地址
      window.location.href =
        window.g.ub_index == 2
          ? window.g.MOBILE_URL_BO
          : window.g.ub_index == 3
          ? window.g.MOBILE_URL_U
          : window.g.MOBILE_URL + '/';
    }
    var menutotalstore = JSON.parse(localStorage.getItem('menutotal'));
    if (menutotalstore) {
      if (menutotalstore.time > new Date().getTime()) {
        //that.$store.commit('changeEvery', { key: 'menutotal', value: menutotalstore.data });
        that.menutotal = menutotalstore.data;
      }
    }
    // 检测url是否含有参数
    //节流
    this.filwindowScroll = this.YS.fnThrottle(this.windowScroll, 200);
    // this.filact.category_id=this.$route.query.category_id ? this.$route.query.category_id : 1;
  },
  destroyed() {
    window.removeEventListener('scroll', this.filwindowScroll);
  },
  async mounted() {
    window.addEventListener('scroll', this.filwindowScroll);
    //获取菜单栏
    if (!that.menutotal[0]) {
      try {
        let data = await that.api.user.api_categoryhome({});
        data.category_list &&
          data.category_list.forEach((e) => {
            that.$store.commit('changeEvery', { key: 'menudist', value: e.name, key2: e.id });
            if (e.pid == 0) {
              e.list = [];
              that.menutotal.push(e);
            } else {
              that.menutotal.forEach((a, i) => {
                if (a.id == e.pid) {
                  that.menutotal[i].list.push(e);
                }
              });
            }
          });

        //存储至本地
        that.YS.savestore('menutotal', that.menutotal, 600);
      } catch (error) {
        console.log(error);
      }
    }
    document.getElementById('topNav').classList.add('top-nav-white');
    if (Object.keys(that.$route.query).length) {
      Object.keys(that.$route.query).forEach((e) => {
        if (that.$route.query[e] !== 'null' && that.$route.query[e] != 'undefined')
          that.filact[e] = that.$route.query[e];
      });
      that.filact.page && (that.fy.pages.page = that.filact.page);
      that.filact.pagesize && (that.fy.pages.pagesize = that.filact.pagesize);
    }
    that.YS.closeallWin(); //关闭所有弹窗
    that.getvideofilter(); //获取视频筛选词
    that.$refs.top.searchkeyword = that.$route.query.keyword;
    if (that.$route.query.keyword) {
      if (that.$route.query.parent_category_id) {
        let aaa = that.menutotal.filter((item) => item.id == that.$route.query.parent_category_id);
        if (aaa.length) that.searchBefore = aaa[0].name;
      }
      document.getElementsByTagName('title')[0].innerText =
        that.$route.query.keyword +
        (that.searchkeyword && that.searchBefore ? '-' : '') +
        that.searchBefore +
        (that.searchkeyword ? '-' : '') +
        (this.ub_index == 2
          ? '在线搜索-BO影视'
          : this.ub_index == 3
          ? '在线搜索-U影视'
          : '在线搜索-UB影视');
    }
    setTimeout(() => {
      if (that.filact.parent_category_id) {
        that.menutotal.forEach((e, i) => {
          if (e.id == that.filact.parent_category_id) {
            //匹配到顶级分类时
            that.menu_act = i + 1;
            //当存在二级分类时
            if (that.filact.category_id) {
              e.list.forEach((ele, index) => {
                if (ele.id == that.filact.category_id) {
                  that.menu_fu_act = index + 1;
                }
              });
            } else {
              that.menu_fu_act = 0;
            }
          }
        });
      }
    }, 300);
    //获取广告缓存
    var guanggaostore = JSON.parse(localStorage.getItem('guanggaolist_fil'));
    if (guanggaostore) {
      if (guanggaostore.time > new Date().getTime()) {
        that.guanggaolist = guanggaostore.data;
      }
    }
    that.adall('pc-filter-right');
    // searchkeyword
    // that.changerList();
  },
  methods: {
    //校验隐藏的密码（午夜版）
    checkHidden() {
      if (that.loadCli) return false;
      that.loadCli = true;
      that.api.user
        .api_userverifyNightPassword({ password: that.hiddenValue })
        .then(() => {
          that.api.user.api_ad({ symbol: 'pc-midnight', result: 1 }).then((data) => {
            if (data) {
              window.location.href = data.ad.url;
            }
          });
          that.hiddenError = false;
        })
        .catch(() => {
          that.hiddenError = true;
        })
        .finally(() => {
          that.loadCli = false;
        });
    },
    //用于图片加载完毕替换图片
    loadOver(pic, i) {
      var newImg = new Image();
      newImg.onload = () => {
        if (this.video_list && this.video_list[i]) {
          // return true;
          this.video_list[i].pic = newImg.src;
          this.$nextTick(() => {
            that.video_list[i].picLoad = true;
          });
        }
      };
      newImg.src = pic;

      // if (!that.$refs[src]) return;
      // let toTop = that.$refs[src].offsetTop;
      // console.log(toTop);
      // let toTop = src
    },
    //更新跳转路由并获取视频列表
    getrou() {
      that.filact.page = that.fy.pages.page;
      that.filact.pagesize = that.fy.pages.pagesize;
      // that.YS.routetogo({
      //   path: '/fil',
      //   query: that.filact,
      // });
      that.searchkeyword = that.filact.keyword;
      // that.YS.routetogo({
      //   path: '/fil',
      //   query: that.filact,
      // });
      that.getVideolist();

      // window.location.href = this.$route.fullPath;
    },
    //获取视频列表
    getVideolist(isOld) {
      that.fivPushNum = 0;
      for (let key in that.filact) {
        if (that.filact[key] === 'null') that.filact[key] = '';
        // if (that.filact[key] === undefined) that.filact[key] = '';
      }
      if (that.filact.keyword && !isOld) {
        that.filact.sort_field = 'score';
      }

      that.video_list = []; //清空列表
      that.loading = true;
      that.video_enter = false;
      that.api.user
        .api_videolist(that.filact)
        .then((data) => {
          that.video_enter = true;
          that.video_list = data.video_list;

          that.video_list.forEach((e, i) => {
            that.loadOver(e.pic, i);
            that.video_list[i].pic = '';
          });
          that.video_total = data.video_total;
          that.fy.pagetotal = data.video_total;
          that.YS.jilu(that.fy);
          if (that.$route.query.parent_category_id) {
            let aaa = that.menutotal.filter(
              (item) => item.id == that.$route.query.parent_category_id,
            );
            if (aaa.length) that.searchBefore = aaa[0].name;
          }
          document.getElementsByTagName('title')[0].innerText =
            that.searchkeyword +
            (that.searchkeyword && that.searchBefore ? '-' : '') +
            that.searchBefore +
            (that.searchkeyword ? '-' : '') +
            (this.ub_index == 2
              ? '在线搜索-BO影视'
              : this.ub_index == 3
              ? '在线搜索-U影视'
              : '在线搜索-UB影视');
          // that.routerRefresh(); //调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程
          this.$nextTick(() => {
            this.loading = false;
          });
        })
        .catch((error) => {
          console.log(error.message);
          that.video_enter = true;
          this.$nextTick(() => {
            this.loading = false;
          });
        });
    },
    //二次加载，push添加数据
    async pushGetVideolist() {
      if (that.fivPushNum < 0) return;
      that.fivPushNum += 1;
      that.filact.page = that.fy.pages.page;
      that.filact.pagesize = that.fy.pages.pagesize;
      let data = await that.api.user.api_videolist(that.filact);
      //有数据时
      if (data && data.video_list) {
        data.video_list.forEach((e) => {
          that.video_list.push(e);
        });
        // let nowindex = (that.filact.page - 1) * that.filact.pagesize;
        that.video_list.forEach((e, i) => {
          try {
            if (that.video_list[i] && that.video_list[i].picLoad) {
              return;
            }
            that.loadOver(e.pic, i);
            that.video_list[i].pic = '';
          } catch (error) {
            console.log(error);
          }
        });
        that.video_total = data.video_total;
        that.fy.pagetotal = data.video_total;
        that.YS.jilu(that.fy);
        that.fivPushNum -= 1;
      } else if (data && !data.video_list) {
        //没有数据时
        that.fivPushNum = -1;
        // that.$refs.top.messTop = '没有更多内容啦';
        // that.$refs.top.mess_show = true;
        // setTimeout(() => {
        //   that.$refs.top.mess_show = false;
        // }, 3000);
      }
    },
    // 滚动条距离页面顶部的距离
    windowScroll() {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      let scrollTop =
        window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      let seeheight = document.documentElement.clientHeight || document.body.clientHeight;

      let bottom_height = document.getElementsByClassName('bottomall')[0].offsetTop;
      // console.log(scrollTop + seeheight);
      // console.log();
      // console.log(bottom_height);

      //筛选页滑到下面时
      // console.log(filheight);
      // console.log(scrollTop + seeheight);
      if (
        that.video_total &&
        bottom_height &&
        bottom_height <= scrollTop + seeheight + 500 &&
        (that.fy.pages.page += 1)
      ) {
        //加载第N页，并添加到里面
        that.pushGetVideolist();
      }
    },
    // 获取视频筛选词
    getvideofilter() {
      let symbol = {};
      if (
        that.menutotal.findIndex(
          (item) => item.id == that.filact.parent_category_id && item.symbol === 'night',
        ) >= 0
      ) {
        symbol.category_symbol = 'night';
      }
      that.api.user.api_videofilter(symbol).then((data) => {
        that.ccc1 = data;
        // Object.keys(that.ccc2).forEach((e) => {
        //   if (that.filact[e]) that.ccc2[e] = that.ccc1[e].indexOf(that.filact[e]);
        // });
        this.$nextTick(() => {
          that.changerList(null);
        });
      });
    },
    // 大分类点击事件
    changerFilter(i, id, symbol) {
      if (symbol === 'night') {
        if (that.$store.state.islogin) {
          that.YS.gotoVip({}, 'open', 'hiddenWIN');
        } else {
          that.$refs.top.usepubilDialog({
            title: '该功能需要登录用户才可以体验，请先登录',
            type: 'needlogin',
            code: false,
            failbutton: '立即登录',
          });
        }
        return;
      }
      that.fy.pages.page = 1;
      that.filact.category_id = null;
      that.filact.page = 1;
      // var obj1 = document.getElementsByClassName('filter_list');
      if (!i) {
        //全选按钮，【按钮已砍掉-2021-05-08】
        // that.YS.actlist(obj1, 0, 'filter_list-act');
        that.filact.parent_category_id = null; //默认null
        that.getrou();
      } else {
        // that.YS.actlist(obj1, i, 'filter_list-act');
        that.filact = that.filactNone;
        that.filact.parent_category_id = id; //记录当前的选择的ID

        that.menu_act = i; //记录当前的选择的下标
        that.menu_fu_act = 0; //默认给二级分类下表0（全部）
        if (that.$route.query.parent_category_id) {
          let aaa = that.menutotal.filter(
            (item) => item.id == that.$route.query.parent_category_id,
          );
          if (aaa.length) that.searchBefore = aaa[0].name;
        }
        document.getElementsByTagName('title')[0].innerText =
          that.searchkeyword +
          (that.searchkeyword && that.searchBefore ? '-' : '') +
          that.searchBefore +
          (that.searchkeyword ? '-' : '') +
          (this.ub_index == 2
            ? '在线搜索-BO影视'
            : this.ub_index == 3
            ? '在线搜索-U影视'
            : '在线搜索-UB影视');
        //跳转新页面
        const obj = Object.assign({ keyword: that.$route.query.keyword }, that.filact);
        // debugger;
        that.YS.routetogo({ path: '/fil', query: obj });
        // that.getrou();
      }
    },
    // 小分类点击事件
    changerList(type, i) {
      if (type != null) that.fy.pages.page = 1;
      //如果没传参，默认全选
      var typeAfter;
      switch (type) {
        case 'regions':
          typeAfter = 'region';
          break;
        case 'languages':
          typeAfter = 'language';
          break;
        case 'years':
          typeAfter = 'year';
          break;
        case 'states':
          typeAfter = 'state';
          break;
        case 'tags':
          typeAfter = 'tag';
          break;

        default:
          typeAfter = type;
          break;
      }
      if (!type) {
        // Object.keys(that.ccc2).forEach((e) => {
        //   // var obj = document.getElementsByClassName(e + '_fil');
        //   // that.YS.actlist(obj, that.ccc2[e] + 1, 'fil-act');
        // });
        if (type != undefined) that.filact[typeAfter] = null;
        // that.getVideolist();

        that.getrou();
      } else {
        // var obj1 = document.getElementsByClassName(type + '_fil');
        that.ccc2[type] = i; //改变样式选择的下标
        that.filact[typeAfter] = that.ccc1[type][i]; //改变当前记录的选择内容
        // if (i == null) {
        //   that.YS.actlist(obj1, 0, 'fil-act');
        // } else {
        //   that.YS.actlist(obj1, that.ccc2[type] + 1, 'fil-act');
        // }
        that.getrou();
      }
    },
    //点击二级联动分类
    changecategory_id(index, id) {
      that.fy.pages.page = 1;
      //这里的index默认+1了，为了对应外面的全部按钮
      if (index) {
        that.filact.category_id = id;
      } else {
        that.filact.category_id = null;
      }
      that.menu_fu_act = index;
      that.getrou();
    },
    //是否付费筛选
    changepaid(index, value) {
      that.fy.pages.page = 1;
      // that.YS.actgo('paid_fil', 'fil-act', index);
      // that.filact.paid = JSON.stringify(value);
      index;
      that.filact.paid = value;
      that.getrou();
    },

    // clifavorite(in_favorite, id, index) {
    //   if (in_favorite) {
    //     // 已经收藏的情况
    //     //发送取消收藏
    //     that.api.user.api_videodeleteFavorite({ video_id: id }).then(() => {
    //       that.video_list[index].in_favorite = 0;
    //     });
    //   } else {
    //     // 未收藏的情况
    //     //发送收藏视频
    //     that.api.user.api_videofavorite({ video_id: id }).then(() => {
    //       that.video_list[index].in_favorite = 1;
    //     });
    //   }
    // },
    // 点击收藏视频按钮
    clifavorite(in_favorite, id, index) {
      if (that.$store.state.islogin) {
        //已登录
        that.gofavorite(in_favorite, id, index);
      } else {
        //未登录
        that.$store.commit('addloginBefore', () => {
          that.gofavorite(in_favorite, id, index);
        });
        that.YS.gotoVip({}, 'open', 'loginReg'); //打开弹窗
        that.$refs.top.TOvercode(); //获取验证码
      }
    },
    gofavorite(in_favorite, id, index) {
      if (in_favorite) {
        // 已经收藏的情况
        //发送取消收藏
        that.api.user.api_videodeleteFavorite({ video_id: id }).then(() => {
          that.video_list[index].in_favorite = 0;
        });
      } else {
        // 未收藏的情况
        //发送收藏视频
        that.api.user.api_videofavorite({ video_id: id }).then(() => {
          that.video_list[index].in_favorite = 1;
        });
      }
    },
    // 获取广告
    adall(symbols) {
      if (JSON.stringify(that.guanggaolist) == '{}') {
        that.api.user.api_adall({ symbols: symbols }).then((data) => {
          that.guanggaolist[symbols] = data[symbols];
          //存储至本地
          that.YS.savestore('guanggaolist_fil', data[symbols], 600, symbols);
        });
      }
    },
    //点击广告
    cliguanggao(type) {
      // that.guanggaolist[type][0];
      window.open(that.guanggaolist[type][0].url);
    },
    //点击排序
    clisort(item, index) {
      var fil_seleb = document.getElementsByClassName('fil_sele-button');
      var fil_selebicon = document.getElementsByClassName('fil_sele-button')[index].childNodes[1];
      that.filact.sort_field = item.type;
      that.filact.page = 1;
      if (that.sortact != index) {
        //第一次点击
        that.sortact = index;
        that.filact.sort_type = 'desc';
        Array.from(fil_seleb).forEach((e) => {
          e.childNodes[1].classList.remove('xz180');
        });
      } else {
        //第2次以上点击，更换排序
        if (that.filact.sort_type == 'asc') {
          that.filact.sort_type = 'desc';
          fil_selebicon.classList.remove('xz180');
        } else if (that.filact.sort_type == 'desc') {
          that.filact.sort_type = 'asc';
          fil_selebicon.classList.add('xz180');
        }
      }
      setTimeout(() => {
        that.getVideolist(true);
      }, 0);
    },
    //搜索词点叉
    nonekeywordSearch() {
      that.filact.keyword = '';
      that.searchkeyword = '';
      that.YS.routetogo({ path: '/fil', query: that.filact });
      // that.getVideolist();
    },
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query.query != from.query.query) {
        this.query = to.query.query;
        // this.init(); //重新加载数据
      }
    },
    // video_list() {
    //   this.$nextTick(function () {
    //     /*现在数据已经渲染完毕*/
    //     this.loading = false;
    //   });
    // },
    // menu_fu_act: {
    //   handler() {
    //     setTimeout(() => {
    //       that.YS.actgo('fil_fenlei', 'fil-act', that.menu_fu_act);
    //     }, 100);
    //   },
    // },
  },
};
</script>
<style lang="scss">
img[src=''],
img:not([src]) {
  background: url('../assets/img/fil_b.png') no-repeat center;
  background-size: 100% 100%;
}

// .top-nav {
//   background: white;
//   .tag-input {
//     background: #eeeeee;
//     // border: 1px solid rgba(255, 255, 255, 0.43137254901960786);
//   }
//   .tag-span,
//   .right-span {
//     color: rgba(51, 51, 51, 1);
//   }
//   .bubble {
//     background: #91d6ff;
//   }
//   .map_icon {
//     background-image: url(../assets/img/down2.png);
//   }
//   .ri_3 {
//     background-image: url(../assets/img/recording4_black.png);
//   }
//   .ri_4 {
//     background-image: url(../assets/img/notice4_black.png);
//   }
// }
.filter_m_list2 {
  margin-bottom: 48px;
}
.fil_q_total {
  display: flex;
  flex-direction: column;
}
.fil_q_box {
  display: flex;
  flex-direction: row;
}
.fil_a_total {
  min-height: 357px;
  display: flex;
  flex-flow: wrap;
  margin-top: 10px;
}
.fil_q_left {
  img {
    height: 310px;
    margin-bottom: 36px;
    border-radius: 8px;
  }
}
</style>
